import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { debounce } from 'lodash'
import NewsList from './NewsList'
import { NEWS_TAG_IDENTIFIER } from '../Blogs/constants'
import BlogSearchInput from '../Blogs/BlogSearchInput'
import useCommonFunctionality from '../Blogs/useCommonFunctionality'
import { generateBlogItemsSchema } from '../../lib/generate-schema'
import './news.scss'

interface Props {
  title: string | React.ReactNode
  prefix: string
  newsType: string
  tags: string[]
  searchOption?: boolean
}

const News: React.FC<Props> = ({
  title,
  prefix,
  newsType,
  tags,
  searchOption = true,
}) => {
  const { setSearchTermNews, blogs } = useCommonFunctionality({ tags })

  let blogIdentifier: unknown

  if (newsType === 'news') {
    blogIdentifier = NEWS_TAG_IDENTIFIER
  }
  const [selectedTag, setSelectedTag] = useState(blogIdentifier)

  let filteredBlogs = blogs
  if (selectedTag !== blogIdentifier) {
    filteredBlogs = filteredBlogs.filter((b: unknown) =>
      b.tags.reduce((has: boolean, t: unknown) => {
        if (has) return has
        return t.name === selectedTag
      }, false)
    )
  }

  const updateSearchTerm = debounce((s: string) => {
    setSelectedTag(blogIdentifier)
    setSearchTermNews(s)
  }, 500)

  // news items
  const newsItems = filteredBlogs?.map((b: unknown) => {
    return (
      <NewsList
        key={b.id}
        title={b.title}
        slug={b.slug}
        html={b.html}
        prefix={b.prefix || prefix}
        feature_image={b.feature_image}
        publishing_date={new Date(b.published_at).toLocaleDateString()}
      />
    )
  })

  const blogItemsSchema = JSON.stringify(
    generateBlogItemsSchema({
      blogPrefix: prefix,
      blogItems: filteredBlogs,
    })
  )

  return (
    <div id="news">
      <Container>
        <Row>
          <Col>
            <div className="newsHeader">
              <h1 className="title">{title}</h1>
              {searchOption && (
                <BlogSearchInput onChange={s => updateSearchTerm(s)} />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="blogs-count">
              {`${filteredBlogs?.length} news found in this Guide`}
            </div>
            <Helmet>
              <script defer type="application/ld+json">
                {blogItemsSchema}
              </script>
            </Helmet>
            <div className="news-list">{newsItems}</div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default News
