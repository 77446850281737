import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import News from '../components/News/News'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../lib/generate-schema'
import { NEWS } from '../components/internal-links'
import { LATEST_RC } from '../components/download-links'
import NEWS_PAGES from '../news-tag'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'News',
    url: NEWS,
  },
]

const NewsListings: React.FC = () => {
  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={LATEST_RC}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="News | Mega"
        description="Get to know more about listings. We have listed 15+ articles for you. Read now."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <News
        newsType="news"
        tags={NEWS_PAGES}
        prefix={`/${process.env.GATSBY_CMS_NEWS_PREFIX}` || '/'}
        title="News"
      />
    </Layout>
  )
}

export default NewsListings
